.general {
  /* background-color: #058cf2; */
  /* background-color: #498cf2; */
  /* background-color: #054ae8; */
  font-size: 20px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
}
.general:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 0 0);
  cursor: pointer;
}
.primary {
  /* background-color: #058cf2; */
  background-color: rgb(49, 108, 244);
  color: white;
}
.danger {
  background-color: red;
  color: white;
}
.black {
  background-color: rgb(0, 0, 0);
  color: white;
}
.btn-activity {
  width: 200px;
  height: 90px;
  border-radius: 0px;
  background-color: rgba(190, 152, 103, 1);
  color: white;
}
.btn-border {
  width: 210px;
  height: 90px;
  border-radius: 0px;
  background-color: transparent;
  color: white;
  font-size: 24px;
  border: 2px solid rgba(255, 255, 255, 1);
  transition: 0.25s ease-in-out;
}
.btn-border:hover {
  border: none;
  background-color: rgba(190, 152, 103, 1);
}
.btn-border-sm {
  width: 160px;
  height: 70px;
}
.btn-learn-more {
  border-radius: 50px;
  color: white;
  background-color: #0d6efd;
}
.btn-add-details {
  border-radius: 5px;
  color: black;
  background-color: rgb(250, 250, 250);
  padding: 0px 10px;
  border: 1px solid black;
}
.btn-learn-more:hover {
  cursor: pointer;
}
.btn-contact-us {
  border-radius: 50px;
  color: black;
  background-color: white;
  border: 1px solid rgba(255, 204, 138, 1);
}
.btn-contact-us:hover {
  color: white;
  background-color: rgba(255, 204, 138, 1);
  filter: brightness(115%);
}

/* move to shared css */
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.float-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.side-info-margin {
  margin: 10px 40px 30px 40px;
}
.slideshow-margin {
  margin: 20px 0;
}
