.about-container {
  width: 100%;
}
.en-title {
  font-size: 50px;
  color: white;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  line-height: 100px;
}
.ch-title {
  font-size: 50px;
  font-weight: 400;
}
.info-div {
  background-color: white;
  padding: 20px 5%;
  color: black;
  font-size: 22px;
  text-align: justify;
}
.info-div p {
  padding-bottom: 20px;
  line-height: 40px;
  /* font-size: 24px; */
  /* font-weight: 300; */
}
.info-div p b {
  text-shadow: none;
  /* color: rgba(255, 255, 255, 1); */
  color: black;
  font-weight: 500;
}
.image-container {
  width: 90vw;
  overflow: hidden;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 20px 0;
}
.image-container img {
  width: 100%;
}
.team-div,
.value-div {
  padding: 50px 5%;
  font-size: 22px;
  text-align: justify;
  display: flex;
}
.pb-40 {
  padding-bottom: 40px;
}
.left-title-div {
  flex-basis: 25%;
}
.right-content-div {
  flex-basis: 75%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.horizontal-center {
  margin-left: 50%;
  transform: translateX(-50%);
}
.value-div {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
}
.filler {
  width: 400px;
}
.profile-component {
  width: 100%;
  overflow: hidden;
  border: 2px solid rgba(158, 160, 164, 0.2);
  margin-bottom: 50px;
}
.profile-component img {
  width: 100%;
  /* height: auto; */
  /* object-fit: cover; */
  /* margin-left: 50%; */
  /* transform: translateX(-50%); */
}
.profile-text-container {
  padding: 20px 50px;
}
.profile-text-container i {
  font-size: 30px;
  color: #21bf28;
  padding: 0;
  /* margin-top: -20px; */
}
.profile-name {
  font-weight: 600;
  margin: 0 0 10px 0;
}
.profile-info {
  font-size: 18px;
  font-weight: 300;
  text-shadow: 0.1px 0px 0px rgba(0, 0, 0, 1);
}
.value-component {
  width: 400px;
  padding: 0 20px;
  margin-bottom: 50px;
}
.value-component p:nth-child(1) {
  font-size: 50px;
  font-weight: 700;
  margin: 0;
}
.value-component p:nth-child(2) {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 25px;
  margin: 10px 0;
}
.value-component p:nth-child(3) {
  font-size: 25px;
}
