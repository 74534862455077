.slideshow-container {
  position: relative;
}
.info-container {
  width: 100%;
  height: 100vh;
  position: relative;
  color: white;
  z-index: 2;
}
.text {
  font-weight: 600;
  font-size: 25px;
  width: 50vw;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.overlay {
  height: 100vh;
  width: 100%;
  background-color: black;
  opacity: 0.2;
  position: absolute;
  z-index: 1;
  top: 0;
}
.overflow-image-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  z-index: 0;
  background-color: rgba(37, 37, 37, 1);
}
.activityImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.navigator-container {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 3;
}
.slideshow-navigator {
  width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
}
.navigator {
  background-color: rgba(37, 37, 37, 1);
  line-height: 60px;
  transition: 0.25s ease-in-out;
  border-radius: 2px;
  text-align: center;
  color: rgba(255, 255, 255, 0.2);
  padding: 0 5px;
}
.navigator:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  padding: 0 15px;
  font-size: 18px;
}
.arrow-left,
.arrow-right {
  background-color: rgba(37, 37, 37, 1);
  line-height: 60px;
  transition: 0.25s ease-in-out;
  border-radius: 2px;
  text-align: center;
  color: rgba(255, 255, 255, 0.2);
  padding: 0 5px;
  width: 100px;
  position: relative;
}
.arrow-left {
  margin-left: -60px;
}
.arrow-right {
  margin-right: -60px;
}
.arrow-left span,
.arrow-right span {
  padding: 0 5px;
}
.arrow-left:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  margin-left: 0px;
}
.arrow-right:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  margin-right: 0px;
}
.arrow-left i,
.arrow-right i {
  font-size: 18px;
  line-height: 60px;
}
.bottom-container {
  position: relative;
  width: 100%;
  display: flex;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 300;
  color: white;
}
.activity-div {
  padding: 25px;
  flex-basis: 100%;
  background-color: rgba(37, 37, 37, 1);
}
