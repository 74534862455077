.service-content {
  display: flex;
  background-color: rgba(158, 160, 164, 0.2);
}
.service-info {
  flex: 1;
  order: 1;
  padding: 40px;
}
.service-info p {
  margin: 0;
}
.service-info p:nth-child(1) {
  margin: 0 5px 5px 5px;
}
.service-info p:nth-child(3) {
  margin-top: 10px;
  margin-bottom: 25px;
}
.service-image {
  flex: 1;
  order: 2;
  min-width: 50vw;
  max-width: 50vw;
  /* height: 400px; */
}
.service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.small-text {
  font-size: 16px;
  text-transform: uppercase;
}
.big-text {
  font-family: 'Roboto', sans-serif;
  font-size: 60px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 65px;
  padding: 10px 0;
}
.normal-text {
  font-size: 17px;
  text-align: justify;
}
