.rotate-container {
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  width: 100%;
  color: white;
}
.rotate-container img {
  width: 300px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}
.positioning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.phone {
  width: 55px;
  height: 100px;
  border-radius: 10px;
  border: 2px solid white;
  transform: rotateZ(20deg);
}
.rotate-text {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
