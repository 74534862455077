.result-container {
  width: 80vw;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: justify;
}
.image-container {
  width: 80vw;
}
.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.type {
  font-size: 40px;
  padding: 20px;
  font-weight: 500;
}
.desc {
  padding: 20px;
  font-size: 20px;
  line-height: 35px;
}
.red {
  color: crimson;
}
.orange {
  color: orange;
}
.green {
  color: green;
}
.blue {
  color: #498cf2;
}

.button-container {
  float: right;
}
i {
  font-size: 30px;
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.5);
}
i:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 1);
}
canvas {
  width: 100%;
  padding: 20px 0;
}
.width-800px {
  width: 600px !important;
  margin: auto;
}
.note {
  width: 100%;
  font-size: 20px;
}

/* mobile */
.result-image {
  width: 80vw;
  overflow: hidden;
  position: relative;
  background-color: white;
}
.result-image img {
  max-width: 80vw;
  object-fit: cover;
}
