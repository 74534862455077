.navbar-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 10px 0;
  width: 100%;
}
.logo {
  height: 50px;
  padding-left: 20px;
}
.navlink,
.navlink-black {
  text-decoration: none;
  font-size: 18px;
  margin: 30px 20px;
  line-height: 50px;
  transition: 0.3s;
  position: relative;
}
.navlink:hover,
.navlink-black:hover {
  transition: 0.3s ease-in-out;
}
.navlink:after,
.navlink-black::after {
  bottom: -15px;
  content: '';
  height: 3px;
  left: 50%;
  position: absolute;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.navlink,
.navlink:hover {
  color: white;
}
.navlink:after {
  background-color: white;
}

.navlink-black,
.navlink-black:hover {
  color: black;
}
.navlink-black::after {
  background-color: black;
}

.navigation-container a:hover:after {
  width: 100%;
  left: 0;
}
.show-menu-btn,
.hide-menu-btn {
  transition: 0.3s;
  /* color: white; */
  font-size: 25px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
}
.show-menu-btn i {
  /* color: black; */
}
.hide-menu-btn i {
  display: none;
  /* color: black; */
  font-size: 30px;
  position: fixed;
  right: 20px;
}
.solid {
  background-color: rgba(0, 0, 0, 0.9);
}
.hide {
  display: none;
}
.show {
  display: block;
}

/* media mobile menu */
.mobile-navigation-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(37, 37, 37, 1);
  top: 0;
  right: -100%;
  text-align: center;
  overflow: scroll;
  display: none;
}
.mobile-navigation-container a {
  margin: 5px;
  padding: 10px;
  display: block;
}
.mobile-navigation-container a:first-child {
  margin-top: 80px;
}
.mobile-navigation-container a:hover {
  background-color: rgba(190, 152, 103, 1);
}
.mobile-navigation-container a:hover:after {
  width: 0;
}
#chk:checked ~ .mobile-navigation-container {
  right: 0;
  display: block;
}
#chk:checked ~ .show-menu-btn i {
  display: none;
}
#chk:checked ~ .hide-menu-btn i {
  display: block;
}
.short {
  height: 45px;
  margin: 5px -5px;
}
.biggerLogo {
  height: 50px;
  margin: 7.5px 10px;
}
.whiteBackground {
  background-color: white;
}
.white i {
  color: white;
}
