body {
  margin: 0;
  font-family: 'Noto Sans SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding-right: 0px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* shared css, will affect every component */
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.width-100-container {
  width: 100%;
}

.height-80-container {
  height: 80vh;
}

.height-80-container img,
.width-100-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-justify {
  text-align: justify;
}

.small-text {
  font-size: 16px;
  text-transform: uppercase;
}

.big-text {
  font-family: 'Roboto', sans-serif;
  font-size: 60px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 65px;
}

.normal-text {
  font-size: 17px !important;
  text-align: justify;
}

.text-center {
  text-align: center;
}

.normal-hover {
  color: rgba(255, 255, 255, 0.2);
  transition: 0.25 ease-in-out;
}

.normal-hover:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
}
.block {
  display: block !important;
}
.flex {
  display: flex !important;
}
.none {
  display: none !important;
}
.full {
  width: 100% !important;
}
.relative {
  position: relative !important;
}
.ph-5percent {
  padding: 0 5%;
}
.fullWidthImg {
  /* width: 99vw !important; */
  min-width: 100% !important;
  max-width: 100% !important;
}
.mobileTextDiv {
  width: 55% !important;
}
.mobileSmallText {
  font-size: 12px !important;
}
.mobileBigText {
  padding: 5px 0 20px 0;
  font-size: 40px !important;
  line-height: 45px !important;
}
.mobileNormalText {
  font-size: 16px !important;
}
.auto-vh {
  height: auto !important;
}
.reset-margin-transform {
  margin: auto !important;
  transform: none !important;
}
.catchphraseMobile {
  top: auto !important;
  bottom: 0 !important;
  padding: 30px;
}
.selected:before {
  content: '';
  bottom: -15px;
  height: 3px;
  left: 50%;
  position: absolute;
  background: white;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 100%;
  left: 0;
}
.white {
  background-color: rgba(255, 255, 255, 1) !important;
}
.transparent {
  background-color: transparent !important;
}
.vh-desktop {
  height: calc(100vh - 80px - 80px) !important;
}
