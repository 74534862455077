.body {
  font-family: 'Noto Sans SC', sans-serif;
}
.disc-container {
  /* background-color: white; */
  padding: 0 5% 5% 5%;
}
.background-image {
  opacity: 0.3;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  transform: translate(-50%, -50%);
  z-index: -10;
}
.banner {
  width: 80vw;
  margin-left: 50%;
  transform: translateX(-50%);
  height: auto;
}
.initial-indent {
  width: 20px;
  float: left;
}
.text-indent {
  width: 170px;
  float: left;
}
.logo-center {
  display: block;
  height: 80px;
  margin: 0 auto;
  margin-top: -30px;
  /* background-color: white; */
}

.disc-form {
  width: 90%;
  max-width: 500px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0px 10px 10px 10px rgba(0, 0, 0, 0.3);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 50px;
  border-radius: 5px;
}
.disc-form select {
  height: 50px;
  width: 100%;
  padding: 10px;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  font-size: 18px;
}

/* shared */
.red {
  font-weight: bold;
  color: crimson;
}
.orange {
  font-weight: bold;
  color: orange;
}
.green {
  font-weight: bold;
  color: green;
}
.blue {
  font-weight: bold;
  color: #498cf2;
}
