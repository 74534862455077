.home-container {
  width: 100%;
  overflow: hidden;
}
.join-us {
  position: relative;
}
.join-us-img {
  height: 100vh;
}
.join-us .overlay {
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  position: absolute;
  top: 0;
}
.join-us .join-us-text {
  width: 100%;
  position: absolute;
  color: white;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 50px 10%;
}
.join-us p:nth-child(1) {
  font-size: 8vmin;
  margin-bottom: 30px;
}
.join-us p:nth-child(2) {
  font-size: 4vmin;
  margin-bottom: 40px;
}
/* side info */
.side-info-container {
  display: flex;
  justify-content: space-evenly;
}
.side-info {
  flex-basis: 45%;
  justify-content: space-evenly;
  margin: 20px 0 50px 0;
  border-radius: 2px;
  border: 1px solid rgba(158, 160, 164, 0.2);
  text-align: justify;
}
.side-info p:nth-of-type(1) {
  padding: 20px 40px 10px 40px;
  font-size: 26px;
  font-weight: 700;
  margin: 0;
}
.side-info p:nth-of-type(2) {
  padding: 10px 40px 20px 40px;
  font-size: 18px;
  margin: 0;
}
.side-info hr {
  opacity: 0.2;
}
.side-info-title {
  margin: 40px 0 20px 0;
  padding: 0px 80px;
  font-size: 40px;
}
.service-navigator {
  display: flex;
  /* background-color: rgba(21, 38, 73, 1); */
  background-color: #00295c;
  overflow-x: scroll;
  overflow-y: hidden;
}
.service-navigator div {
  flex: 1;
  min-width: 100px;
  justify-content: space-evenly;
  margin: 15px;
  text-align: center;
}
.service-navigator div:nth-of-type(1) {
  /* this is input radio division styling */
  position: absolute;
  display: none;
}
.service-navigator div label {
  width: auto;
  font-size: 20px;
  font-weight: 400;
  color: white;
  position: relative;
}
.service-navigator div label:hover {
  cursor: pointer;
  transition: 0.3 ease-in-out;
  /* color: #0d6efd; */
  color: #cfcfcf;
}
.service-navigator div label::after {
  content: '';
  bottom: -15px;
  height: 3px;
  left: 50%;
  position: absolute;
  /* background: #0d6efd; */
  background-color: #cfcfcf;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.service-container div label:hover:after {
  width: 100%;
  left: 0;
}
.catchphrase-container {
  position: relative;
  height: 100vh;
  width: 90vw;
  margin: auto;
}
.image-container {
  position: absolute;
  /* width: 50vw; */
  /* flex-basis: 50%; */
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}
.image-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.width-50vw {
  width: 50vw;
}
.catchphrase-text-container {
  position: absolute;
  width: 40vw;
  top: 50%;
  right: 0%;
  transform: translateY(-50%);
}
.catchphraseMinHeight {
  height: 700px;
}
.small-text {
  font-size: 16px;
  text-transform: uppercase;
}
.big-text,
.slide-big-text {
  font-family: 'Roboto', sans-serif;
  font-size: 60px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 65px;
}
.big-text {
  padding: 20px 0;
}
.normal-text {
  font-size: 20px;
  text-align: justify;
}
.slide-big-text {
  padding: 10px 0;
}
p {
  margin: 0;
}
.slideshow-container {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
}
.slideshow-image-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 500%;
  height: 100vh;
  z-index: -1;
}
.slideshow-image-container img {
  flex: 1;
  width: 20%;
  height: 100%;
  object-fit: cover;
}

.slideshow-text-container {
  height: 100vh;
  width: 500%;
  display: flex;
  z-index: 1;
}
.flex-container {
  flex: 1;
  position: relative;
  z-index: 2;
}
.slideshow-text {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 50vw;
  color: white;
  padding: 0 5%;
  text-align: justify;
}
.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  height: 100vh;
}
.slideshow-navigator-container {
  position: absolute;
  bottom: 20px;
  left: 0;
  overflow: hidden;
  height: 10vh;
  width: 100%;
  z-index: 2;
}
.gradient-overlay {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0.1) 70%
  );
  /* background: linear-gradient(
    90deg,
    rgba(7, 36, 83, 0.9) 0%,
    rgba(25, 70, 143, 0.7) 30%,
    rgba(9, 9, 121, 0) 70%
  ); */
  height: 100vh;
  width: 100vw;
}
.navigator-container {
  position: absolute;
  bottom: 10%;
  left: 0;
  padding: 0 5%;
  cursor: pointer;
}
.navigation-filler,
.navigation-manual {
  display: flex;
  position: absolute;
}
.navigation-filler div,
.navigation-manual div {
  margin-right: 3px;
  width: 50px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50px;
  cursor: pointer;
}
.navigation-filler div:hover,
.navigation-manual div:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.6);
}
.navigation-manual div label {
  /* color: transparent; */
  position: absolute;
  font-size: 1px;
  width: 50px;
  height: 5px;
  cursor: pointer;
}
/* .navigation-filler div:nth-child(3) {
  background-color: rgba(255, 255, 255, 1);
} */
.ml-0 {
  margin-left: 0%;
  transition: 1s ease-in-out;
}
.ml-100 {
  margin-left: -100%;
  transition: 1s ease-in-out;
}
.ml-200 {
  margin-left: -200%;
  transition: 1s ease-in-out;
}
.ml-300 {
  margin-left: -300%;
  transition: 1s ease-in-out;
}
.ml-400 {
  margin-left: -400%;
  transition: 1s ease-in-out;
}

/* value */
/* .value-component {
  width: 400px;
  padding: 0 20px;
  margin-bottom: 50px;
}
.value-component p:nth-child(1) {
  font-size: 50px;
  font-weight: 700;
  margin: 0;
}
.value-component p:nth-child(2) {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 25px;
  margin: 10px 0;
}
.value-component p:nth-child(3) {
  font-size: 25px;
}
.value-div {
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 50px 5%;
  font-size: 22px;
  text-align: justify;
  display: flex;
}
.en-title {
  font-size: 50px;
  color: white;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  line-height: 100px;
}
.ch-title {
  font-size: 50px;
  font-weight: 400;
}
.left-title-div {
  flex-basis: 25%;
}
.right-content-div {
  flex-basis: 75%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
} */
.join-us-image,
.mobile-value-image,
.value-image,
.culture-image {
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-color: white;
}
.join-us-image img,
.value-image img,
.culture-image img {
  width: 85vw;
  object-fit: cover;
  margin-left: 50%;
  transform: translateX(-50%);
}
.join-us-image img {
  width: 100vw;
  margin-bottom: -5px;
}
.culture-image {
  padding: 50px 0;
}
.mobile-value-title img {
  max-width: 100px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 20px 0;
}
.mobile-values img {
  width: 85vw;
  max-width: 250px;
  padding-top: 10px;
}
.row {
  margin: 0 auto;
  display: flex;
  width: 85vw;
  flex-wrap: wrap;
  justify-content: space-around;
}
