.banner-container {
  height: 50vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}
.banner-container p {
  position: absolute;
  z-index: 10;
  line-height: 50vh;
  top: 0px;
  left: 0px;
  height: 50vh;
  color: white;
  font-size: 60px;
  margin-left: 10%;
  font-weight: 500;
}
.banner-container p span {
  font-size: 70px;
}
.banner-container img {
  height: 50vh;
  width: 100vw;
  object-fit: cover;
}
.service-container {
  background-color: rgba(231, 231, 231, 1);
}
.service-component-container {
  width: 100%;
  position: relative;
  padding-bottom: 5%;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.card-container {
  margin: 5% 5% 0 5%;
  flex-basis: 1;
  position: relative;
  /* width: 40vh;
  height: 60vh; */
  width: 360px;
  height: 540px;
}
.card {
  position: absolute;
  /* width: 40vh;
  height: 60vh; */
  width: 360px;
  height: 540px;
  transform-style: preserve-3d;
  transition: ease-in-out 0.5s;
}
.card:hover {
  transform: rotateY(180deg);
}
.card-front,
.card-back {
  width: 360px;
  height: 540px;
  border-radius: 30px;
  position: absolute;
  /* width: 40vh;
  height: 60vh; */
  backface-visibility: hidden;
}
.card-front img {
  width: 360px;
  height: 540px;
  position: absolute;
  object-fit: cover;
  /* width: 100%;
  height: 60vh; */
  border-radius: 30px;
}
.card-front p {
  position: absolute;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 15px;
  margin-top: 240px;
  color: white;
  width: 100%;
  text-align: center;
  box-shadow: 0px 1px 15px 5px rgba(0, 0, 0, 0.3);
}

.card-back {
  transform: rotateY(180deg);
  background-color: white;
}
.card-back div {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 3vh;
}

.small-text {
  font-size: 16px;
  padding: 5vh 3vh 0 3vh;
  background-color: rgba(190, 152, 103, 1);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  color: white;
}
.big-text {
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  font-weight: 600;
  padding-bottom: 2vh;
  padding: 0 3vh 3vh 3vh;
  background-color: rgba(190, 152, 103, 1);
  color: white;
}
.normal-text {
  font-size: 20px;
  text-align: justify;
  line-height: 40px;
  padding: 2vh 3vh;
}

/* mobile */
.service-image {
  padding: 5% 0;
  width: 100vw;
  overflow: hidden;
  position: relative;
  background-color: white;
}
.services img {
  min-width: 320px;
  max-width: 45vw;
  padding-top: 10px;
  border-radius: 50px;
}
.row {
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  justify-content: space-around;
}
.mt-5 {
  margin-top: 5%;
}
