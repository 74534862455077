.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.overlay.overlay-active {
  display: block;
}

.sidebar {
  height: 100%;
  width: 200px;
  background-color: #f8f8f8;
  border-right: 1px solid black;
  padding: 20px;
  padding-top: 110px;
  position: fixed;
  margin-top: -90px;
  z-index: 10;
  transition: 0.5s;
}

.sidebar-collapsed {
  height: 100%;
  width: 0px;
  background-color: #f8f8f8;
  border-right: 1px solid black;
  padding-top: 110px;
  position: fixed;
  margin-top: -90px;
  overflow: hidden;
  transition: 0.5s;
  z-index: 10;
}

.item {
  cursor: pointer;
}

.item-text {
  font-size: 20px !important;
  color: black;
  position: relative;
}

.item-text-active,
.item-text:hover {
  font-size: 20px !important;
  color: rgb(16, 106, 219);
  position: relative;
}

.nested-item {
  padding-left: 24px;
}

.nested-item-text {
  color: black;
}

.nested-item-text-active,
.nested-item-text:hover {
  color: rgb(16, 106, 219);
}

.item-text:after {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  border-bottom: solid 3px rgb(16, 106, 219);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.item-text:hover:after {
  transform: scaleX(1);
}

.item-text-active:after {
  display: block;
  position: absolute;
  content: '';
  width: 100%;
  border-bottom: solid 3px rgb(16, 106, 219);
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
}

.trigger-sidebar {
  position: fixed;
  bottom: 15px;
  left: 15px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  cursor: pointer;
  z-index: 11;
}

.sidebar p,
.sidebar span,
.sidebar a,
.sidebar-collapsed p,
.sidebar-collapsed span,
.sidebar-collapsed a {
  white-space: nowrap;
  overflow: hidden;
}
