.footer-container {
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  padding-bottom: 15px;
}
.footer-container div {
  text-align: center;
}
.social-media-container i {
  font-size: 30px;
  margin: 10px 10px;
  color: #afafaf;
}
.community-container,
.navigation-container {
  padding: 20px 0;
}
.community-container {
  flex: 1;
  margin: auto;
}
.navigation-container {
  flex: 1;
}
.footerimg {
  width: 200px;
  opacity: 0.8;
}
.footerimg:hover {
  opacity: 1;
}
.hyperlink {
  text-decoration: none;
  color: #afafaf;
  font-size: 20px;
  display: block;
  padding: 10px 0;
}
.hyperlink:hover {
  color: #ffffff;
}
.padding-none {
  padding: 0;
}
.facebook:hover {
  color: #4267b2;
}
.instagram:hover {
  color: #8a3ab9;
}
.whatsapp:hover {
  color: #21bf28;
}
