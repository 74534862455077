.card-container {
  background-color: white;
  border-radius: 15px;
  width: 80vw;
  margin: 6% auto;
}
.header {
  font-size: 22px;
  font-weight: 900;
  padding: 3% 5%;
  /* background-color: rgb(49, 108, 244); */
  /* background-color: rgba(96, 111, 165, 1); */
  background-image: url('../../images/qc-background.png');
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.content-container {
  padding: 2% 5%;
  font-size: 18px;
  text-align: justify;
  display: grid;
  /* grid-template-areas:
    'header header header header header header'
    'menu main main main right right'
    'menu footer footer footer footer footer'; */
  grid-template-areas: 'left right';
}
.content-container-straight {
  min-height: 250px;
}
.discLabel {
  font-size: 25px;
  text-align: justify;
  padding: 5px 0;
}

/* radio button design */
.content-container label input {
  position: absolute;
  left: -9999px;
}
.content-container label input:checked + span {
  /* background-color: rgb(214, 214, 228); */
  background-color: rgba(91, 138, 235, 0.5);
}
.content-container label input:checked + span:before {
  /* box-shadow: inset 0 0 0 0.4375em #00005c; */
  /* box-shadow: inset 0 0 0 0.4375em rgb(49, 108, 244); */
  box-shadow: inset 0 0 0 0.4375em rgba(96, 111, 165, 1);
}
.content-container label span {
  display: flex;
  align-items: center;
  padding: 0.4em 0.75em 0.4em 0.375em;
  border-radius: 99em;
  white-space: pre-line;
  transition: 0.25s ease;
  float: left;
}
.content-container label span:hover {
  /* background-color: rgb(214, 214, 228); */
  background-color: rgba(91, 138, 235, 0.5);
  cursor: pointer;
}
.content-container label span:before {
  display: flex;
  flex-shrink: 0;
  content: '';
  margin-right: 5px;
  margin-top: 5px;
  background-color: #fff;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em rgb(96, 111, 165);
  /* box-shadow: inset 0 0 0 0.125em rgb(49, 108, 244); */
  /* box-shadow: inset 0 0 0 0.125em #00005c; */
  float: left;
}

/* media screen */
.header-sm {
  font-size: 18px;
}
.content-sm {
  font-size: 16px;
}

/* optional tag */
.roboto {
  font-family: 'Roboto', sans-serif;
}
